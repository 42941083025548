import moment from 'moment'

export const timeTodayDateElse = (date: Date): string => {
    const today = moment()
    const yesterday = moment().subtract(1, 'day')
    let formattedDate = ''

    if (moment(date).isSame(today, 'day')) {
        //console.log('Today')
        formattedDate = moment(date).format('h:mm a')
    } else if (moment(date).isSame(yesterday, 'day')) {
        //console.log('Yesterday')
        formattedDate = 'yesterday'
    } else {
        formattedDate = moment(date).format('MMM D, YYYY')
    }

    return formattedDate
}

export const timeTodayDateElseWithTime = (date: Date): string => {
    const today = moment()
    const yesterday = moment().subtract(1, 'day')
    let formattedDate = ''

    if (moment(date).isSame(today, 'day')) {
        //console.log('Today')
        formattedDate = 'today at ' + moment(date).format('h:mm a')
    } else if (moment(date).isSame(yesterday, 'day')) {
        //console.log('Yesterday')
        formattedDate = 'yesterday at ' + moment(date).format('h:mm a')
    } else {
        formattedDate = moment(date).format('MMM D, YYYY') + ' at ' + moment(date).format('h:mm a')
    }

    return formattedDate
}

export const formatUserContentLinkDate = (dateString: string): string => {
    const date = new Date(dateString)
    const now = new Date()
    const diffTime = Math.abs(now.getTime() - date.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays === 1) {
        return 'Yesterday'
    } else if (diffDays === 2) {
        return 'Two Days Ago'
    } else {
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
    }
}
