import { RecallaiMeeting } from '../types/RecallaiMeeting'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface RecallAiMeetingsApi {
    saveMeetingLink(meetingInfo: RecallaiMeeting): Promise<AxiosResponse<RecallaiMeeting>>
}

export const RecallAiMeetingsApi: RecallAiMeetingsApi = Object.freeze({
    saveMeetingLink(meetingInfo: RecallaiMeeting): Promise<AxiosResponse<RecallaiMeeting>> {
        return ApiService.post(`/recallai-meetings`, {
            meeting_type: meetingInfo.meeting_type,
            meeting_url: meetingInfo.meeting_url,
            join_at: meetingInfo.join_at,
            meeting_status: meetingInfo.meeting_status,
            meeting_title: meetingInfo.meeting_title,
            recallai_bot_id: meetingInfo.recallai_bot_id,
            campaign_id: meetingInfo.campaign_id,
            author_id: meetingInfo.author_id,
            interview_id: meetingInfo.interview_id,
        })
    },
})
