import { UserContentConversation } from '../types/UserContent'

export const mockUserContentConversations: UserContentConversation[] = [
    {
        id: '3',
        title: 'Team Brainstorming Session',
        date: new Date(Date.now() - 86400000).toISOString().split('T')[0], // Yesterday's date
        takeaway_questions: [],
        posts: [
            {
                id: '301',
                title: 'Innovation Focus',
                content:
                    'Excited to share the innovative ideas that emerged from our team brainstorming session yesterday. Our focus on sustainable solutions is set to revolutionize our industry approach. #Innovation #Sustainability',
                original_content: [
                    {
                        version: 1,
                        post: 'Excited to share the innovative ideas that emerged from our team brainstorming session yesterday. Our focus on sustainable solutions is set to revolutionize our industry approach. #Innovation #Sustainability',
                    },
                ],
                likes: 0,
                comments: 0,
                shares: 0,
                is_shared: false,
                user_id: '1',
                user: {
                    id: '1',
                    first_name: 'John',
                    last_name: 'Doe',
                    phone_number: '+1234567890',
                    email: 'john.doe@test.com',
                    created_at: '2025-01-22 17:31:07.765706',
                    username: 'demo1',
                    brand: undefined,
                    bio: '',
                    confirmed: true,
                    insights_enabled: false,
                    account_claimed: true,
                },
                interview_id: '',
                edited: false,
                follow_up_questions: [],
                created_at: '2025-01-22 17:31:07.765706',
                updated_at: '',
            },
            {
                id: '302',
                title: 'Diverse Perspectives',
                content:
                    'One key takeaway from our recent brainstorming: the power of diverse perspectives. When we bring together minds from different backgrounds, the results are truly inspiring. #Diversity #Teamwork',
                original_content: [
                    {
                        version: 1,
                        post: 'One key takeaway from our recent brainstorming: the power of diverse perspectives. When we bring together minds from different backgrounds, the results are truly inspiring. #Diversity #Teamwork',
                    },
                ],
                likes: 0,
                comments: 0,
                shares: 0,
                is_shared: false,
                user_id: '1',
                user: {
                    id: '1',
                    first_name: 'John',
                    last_name: 'Doe',
                    phone_number: '+1234567890',
                    email: 'john.doe@test.com',
                    created_at: '2025-01-22 17:31:07.765706',
                    username: 'demo1',
                    brand: undefined,
                    bio: '',
                    confirmed: true,
                    insights_enabled: false,
                    account_claimed: true,
                },
                interview_id: '',
                edited: false,
                follow_up_questions: [],
                created_at: '2025-01-22 17:31:07.765706',
                updated_at: '',
            },
            {
                id: `303`,
                title: 'AI in Customer Service',
                content:
                    'In our brainstorming session, we identified AI as a game-changer for customer service. Excited to explore how we can leverage AI to enhance customer experiences while maintaining that crucial human touch. #AI #CustomerService',
                original_content: [
                    {
                        version: 1,
                        post: 'In our brainstorming session, we identified AI as a game-changer for customer service. Excited to explore how we can leverage AI to enhance customer experiences while maintaining that crucial human touch. #AI #CustomerService',
                    },
                ],
                likes: 0,
                comments: 0,
                shares: 0,
                is_shared: false,
                user_id: '1',
                user: {
                    id: '1',
                    first_name: 'John',
                    last_name: 'Doe',
                    phone_number: '+1234567890',
                    email: 'john.doe@test.com',
                    created_at: '2025-01-22 17:31:07.765706',
                    username: 'demo1',
                    brand: undefined,
                    bio: '',
                    confirmed: true,
                    insights_enabled: false,
                    account_claimed: true,
                },
                interview_id: '',
                edited: false,
                follow_up_questions: [],
                created_at: '2025-01-22 17:31:07.765706',
                updated_at: '',
            },
            {
                id: `304`,
                title: 'Sustainability Practices',
                content:
                    "Sustainability was a hot topic in our team discussion. We're committed to reducing our carbon footprint and developing eco-friendly products. What sustainable practices has your company adopted? #Sustainability #GreenBusiness",
                original_content: [
                    {
                        version: 1,
                        post: "Sustainability was a hot topic in our team discussion. We're committed to reducing our carbon footprint and developing eco-friendly products. What sustainable practices has your company adopted? #Sustainability #GreenBusiness",
                    },
                ],
                likes: 0,
                comments: 0,
                shares: 0,
                is_shared: false,
                user_id: '1',
                user: {
                    id: '1',
                    first_name: 'John',
                    last_name: 'Doe',
                    phone_number: '+1234567890',
                    email: 'john.doe@test.com',
                    created_at: '2025-01-22 17:31:07.765706',
                    username: 'demo1',
                    brand: undefined,
                    bio: '',
                    confirmed: true,
                    insights_enabled: false,
                    account_claimed: true,
                },
                interview_id: '',
                edited: false,
                follow_up_questions: [],
                created_at: '2025-01-22 17:31:07.765706',
                updated_at: '',
            },
            {
                id: `305`,
                title: 'Continuous Learning',
                content:
                    "Reflecting on our brainstorming session, I'm reminded of the importance of continuous learning and adaptation in today's fast-paced business world. What's your approach to staying ahead of the curve? #ContinuousLearning #BusinessStrategy",
                original_content: [
                    {
                        version: 1,
                        post: "Reflecting on our brainstorming session, I'm reminded of the importance of continuous learning and adaptation in today's fast-paced business world. What's your approach to staying ahead of the curve? #ContinuousLearning #BusinessStrategy",
                    },
                ],
                likes: 0,
                comments: 0,
                shares: 0,
                is_shared: false,
                user_id: '1',
                user: {
                    id: '1',
                    first_name: 'John',
                    last_name: 'Doe',
                    phone_number: '+1234567890',
                    email: 'john.doe@test.com',
                    created_at: '2025-01-22 17:31:07.765706',
                    username: 'demo1',
                    brand: undefined,
                    bio: '',
                    confirmed: true,
                    insights_enabled: false,
                    account_claimed: true,
                },
                interview_id: '',
                edited: false,
                follow_up_questions: [],
                created_at: '2025-01-22 17:31:07.765706',
                updated_at: '',
            },
        ],
    },
    {
        id: '2',
        title: 'Product Roadmap Review',
        date: '2025-01-22',
        takeaway_questions: [],
        posts: [
            {
                id: `201`,
                title: 'Future Features',
                content:
                    'Just wrapped up our product roadmap review. Thrilled about the upcoming features that will help our clients save time and increase engagement. Stay tuned! #ProductDevelopment #AI',
                original_content: [
                    {
                        version: 1,
                        post: 'Just wrapped up our product roadmap review. Thrilled about the upcoming features that will help our clients save time and increase engagement. Stay tuned! #ProductDevelopment #AI',
                    },
                ],
                likes: 32,
                comments: 7,
                shares: 5,
                is_shared: false,
                user_id: '1',
                user: {
                    id: '1',
                    first_name: 'John',
                    last_name: 'Doe',
                    phone_number: '+1234567890',
                    email: 'john.doe@test.com',
                    created_at: '2025-01-22 17:31:07.765706',
                    username: 'demo1',
                    brand: undefined,
                    bio: '',
                    confirmed: true,
                    insights_enabled: false,
                    account_claimed: true,
                },
                interview_id: '',
                edited: false,
                follow_up_questions: [],
                created_at: '2025-01-22 17:31:07.765706',
                updated_at: '',
            },
        ],
    },
    {
        id: '1',
        title: 'Q1 Strategy Meeting',
        date: '2025-01-15',
        takeaway_questions: [],
        posts: [
            {
                id: `101`,
                title: 'Customer Trust',
                content:
                    "Excited to share key insights from our Q1 strategy meeting. We're focusing on innovative solutions to help our clients build trust with their customers. #BusinessStrategy #CustomerTrust",
                original_content: [
                    {
                        version: 1,
                        post: "Excited to share key insights from our Q1 strategy meeting. We're focusing on innovative solutions to help our clients build trust with their customers. #BusinessStrategy #CustomerTrust",
                    },
                ],
                likes: 24,
                comments: 5,
                shares: 3,
                is_shared: false,
                user_id: '1',
                user: {
                    id: '1',
                    first_name: 'John',
                    last_name: 'Doe',
                    phone_number: '+1234567890',
                    email: 'john.doe@test.com',
                    created_at: '2025-01-22 17:31:07.765706',
                    username: 'demo1',
                    brand: undefined,
                    bio: '',
                    confirmed: true,
                    insights_enabled: false,
                    account_claimed: true,
                },
                interview_id: '',
                edited: false,
                follow_up_questions: [],
                created_at: '2025-01-22 17:31:07.765706',
                updated_at: '',
            },
            {
                id: `102`,
                title: 'B2B Marketing',
                content:
                    'In our recent team discussion, we identified that personalized content is key to cutting through the noise in B2B marketing. Looking forward to implementing new strategies! #B2BMarketing #PersonalizedContent',
                original_content: [
                    {
                        version: 1,
                        post: 'In our recent team discussion, we identified that personalized content is key to cutting through the noise in B2B marketing. Looking forward to implementing new strategies! #B2BMarketing #PersonalizedContent',
                    },
                ],
                likes: 18,
                comments: 2,
                shares: 1,
                is_shared: true,
                user_id: '1',
                user: {
                    id: '1',
                    first_name: 'John',
                    last_name: 'Doe',
                    phone_number: '+1234567890',
                    email: 'john.doe@test.com',
                    created_at: '2025-01-22 17:31:07.765706',
                    username: 'demo1',
                    brand: undefined,
                    bio: '',
                    confirmed: true,
                    insights_enabled: false,
                    account_claimed: true,
                },
                interview_id: '',
                edited: false,
                follow_up_questions: [],
                created_at: '2025-01-22 17:31:07.765706',
                updated_at: '',
            },
        ],
    },
]

export const mockQuestion = {
    id: '1',
    slug: 'please-respond-by-video-or-voi-969033',
    question: `Based on your meeting about {{conversationTitle} and your posts on {{topics}}, how would you summarize the key insights for your LinkedIn network?`,
    help_text: 'test',
    is_multi_question_link: false,
    author_id: '1',
    author: {
        id: '1',
        first_name: 'John',
        last_name: 'Doe',
        phone_number: '+1234567890',
        email: 'john.doe@test.com',
        created_at: '2025-01-22 17:31:07.765706',
        username: 'sudip',
        brand: undefined,
        bio: '',
        confirmed: true,
        insights_enabled: false,
        account_claimed: true,
    },
    question_for_user_id: '',
    question_for_user: {
        id: '1',
        first_name: 'John',
        last_name: 'Doe',
        phone_number: '+1234567890',
        email: 'john.doe@test.com',
        created_at: new Date(),
        username: 'sudip',
        brand: undefined,
        bio: '',
        confirmed: true,
        insights_enabled: false,
        account_claimed: true,
    },
    created_at: '2025-01-22 17:31:07.765706',
    updated_at: '',
    is_draft: false,
    question_sequence: 0,
    question_settings: {},
}
