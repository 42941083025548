//import { Button } from '@/components/ui/button'
import { Question } from '../types/Question'
import { Button } from 'flowbite-react'
import { Mic } from 'lucide-react'
import React from 'react'

type VoicePromptProps = {
    question: Question
    onVoicePromptClicked: () => void
}

export function VoicePrompt({ question, onVoicePromptClicked }: VoicePromptProps) {
    return (
        <div className="flex flex-col bg-blue-100 p-4 rounded-lg shadow-md mb-6 mr-2 justify-center">
            <h3 className="text-2xl font-extrabold text-blue-800 mb-2">Chat with Toni</h3>
            <p className="text-blue-700 mb-4 font-bold">{question?.question}</p>
            <div className="flex justify-start">
                <div className="">
                    <Button
                        onClick={onVoicePromptClicked}
                        className="bg-blue-500 hover:bg-blue-600 text-white text-lg font-bold py-1 px-10 rounded-full flex items-center justify-center"
                    >
                        <Mic className="mr-2" />
                        <div className="ml-2 pt-0.5">Talk to Toni</div>
                    </Button>
                </div>
            </div>
        </div>
    )
}
