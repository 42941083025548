import {
    UserContentConversation,
    UserContentPayload,
    UserContentPost,
    UserContentSharePayload,
} from '../types/UserContent'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface UserContentApi {
    getUserContentPosts(
        username: string,
        slug: string,
    ): Promise<AxiosResponse<UserContentConversation[]>>
    updateUserContentPost(payload: UserContentPayload): Promise<AxiosResponse<UserContentPost>>
    copyUserContentPost(payload: UserContentPayload): Promise<AxiosResponse<void>>
    shareUserContentPost(payload: UserContentSharePayload): Promise<AxiosResponse<void>>
}

export const UserContentApi: UserContentApi = Object.freeze({
    getUserContentPosts(
        username: string,
        slug: string,
    ): Promise<AxiosResponse<UserContentConversation[]>> {
        return ApiService.get(`/user-content/${username}/${slug}`)
    },
    updateUserContentPost(payload: UserContentPayload): Promise<AxiosResponse<UserContentPost>> {
        return ApiService.patch(`/user-content/update`, payload)
    },
    copyUserContentPost(payload: UserContentPayload): Promise<AxiosResponse<void>> {
        return ApiService.patch(`/user-content/copied`, payload)
    },
    shareUserContentPost(payload: UserContentSharePayload): Promise<AxiosResponse<void>> {
        return ApiService.post(`/user-content/share`, payload)
    },
})
