import { UserContentApi } from '../api/userContent'
import ContentLinkCard from '../components/ContentLinkCard'
import Spinner from '../components/Spinner'
import { VoicePrompt } from '../components/voice-prompt'
import { mockQuestion, mockUserContentConversations } from '../data/mockData'
import { Question } from '../types/Question'
import { UserContentConversation, UserContentPayload, UserContentPost } from '../types/UserContent'
import { formatUserContentLinkDate } from '../utils/dateTimeFormatter'
import { replacePlaceholders } from '../utils/helpers'
import { ScrollArea } from '@radix-ui/react-scroll-area'
import { Modal, Textarea } from 'flowbite-react'
import { motion } from 'framer-motion'
import { CalendarDays, Check, LoaderCircle } from 'lucide-react'
import type React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

;('use client')

export default function ContentLinkPage() {
    const { username, slug } = useParams<{ username: string; slug: string }>()
    const [searchParams] = useSearchParams()
    const [isUpdating, setIsUpdating] = useState<boolean>(false)
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [unansweredTakeawayLinkExists, setUnansweredTakeawayLinkExists] = useState<boolean>(false)
    const [conversations, setConversations] = useState<UserContentConversation[]>([])
    const [selectedConversation, setSelectedConversation] =
        useState<UserContentConversation | null>(null)
    const [selectedPost, setSelectedPost] = useState<UserContentPost | null>(null)
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
    const [isShared, setIsShared] = useState(false)
    //const [isProfileLinkDialogOpen, setIsProfileLinkDialogOpen] = useState(false)
    //const [linkedInProfileLink, setLinkedInProfileLink] = useState('')
    //const [isAnalyzing, setIsAnalyzing] = useState(false)
    //const [profileSuggestions, setProfileSuggestions] = useState<ProfileSuggestion[]>([])
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const [voicePrompt, setVoicePrompt] = useState<Question | null>(null)
    const [dots, setDots] = useState('.')
    const navigate = useNavigate()

    const refreshVoicePrompt = (conversation: UserContentConversation) => {
        // This is a placeholder function. In a real application, this would be more sophisticated,
        // potentially using AI to generate a relevant question based on the conversation content.
        const topics = conversation.posts.map((post) => post.title).join(', ')
        let question: Question = mockQuestion
        const replacements = {
            '{{topics}}': topics,
            '{{conversationTitle}}': conversation.title,
        }
        question.question = replacePlaceholders(question.question, replacements)

        if (username && username !== 'demo1') {
            setUnansweredTakeawayLinkExists(false)
        }

        if (conversation.takeaway_questions && conversation.takeaway_questions.length > 0) {
            // Sort takeaway questions by created_at date in descending order
            conversation.takeaway_questions.sort((a, b) => {
                const dateA = new Date(a.created_at).getTime()
                const dateB = new Date(b.created_at).getTime()
                return dateB - dateA
            })
            // loop through array of takeaway questions and return the first one that has not been answered
            // if all have been answered, return the the most recent one which should be first in the array
            let answeredTakeawayLinkCount = 0
            for (let i = 0; i < conversation.takeaway_questions.length; i++) {
                if (!conversation.takeaway_questions[i].responded_to_takeaway_link) {
                    question = conversation.takeaway_questions[i]
                    setUnansweredTakeawayLinkExists(true)
                    break
                } else {
                    answeredTakeawayLinkCount++
                }
            }
            if (answeredTakeawayLinkCount === conversation.takeaway_questions.length) {
                question = conversation.takeaway_questions[0]
                setUnansweredTakeawayLinkExists(true)
            }
        }
        setVoicePrompt(question)
    }

    const fetchUserContent = async (isUpdatingContent: boolean) => {
        if (isUpdatingContent) {
            setIsUpdating(true)
            //wait 10 seconds to simulate the time it takes to update the content
            await new Promise((resolve) => setTimeout(resolve, 15000))
            navigate(window.location.pathname, { replace: true })
            //window.location.reload() // Reload the page
        } else {
            setShowSpinner(true)
        }
        try {
            if (username && slug && username !== 'demo1') {
                const response = await UserContentApi.getUserContentPosts(username, slug)
                if (response.status === 200) {
                    // sort the conversations by date in descending order
                    const sortedConversations = response.data.sort((a, b) => {
                        const dateA = new Date(a.date).getTime()
                        const dateB = new Date(b.date).getTime()
                        return dateB - dateA
                    })
                    setConversations(sortedConversations)
                    //setPosts(response.data.posts)
                    //setSelectedPostId(response.data.posts[0].id)
                    setIsSidebarOpen(false)

                    // eslint-disable-next-line no-console
                    // console.log(
                    //     'UserContentPage: fetchUserContent(), Conversations and Posts:',
                    //     sortedConversations,
                    // )
                } else {
                    // Handle error
                    // eslint-disable-next-line no-console
                    console.log('Error fetching user content:', response)
                }
            } else {
                // Use mock data for demo user
                setConversations(mockUserContentConversations)
                //setPosts(mockUserContentConversations[0].posts)
                //setSelectedPostId(mockUserContentConversations[0].posts[0].id)
                setIsSidebarOpen(false)

                // eslint-disable-next-line no-console
                console.log(
                    'UserContentPage: fetchUserContent(), Mock Conversations:',
                    conversations,
                )
            }
        } catch (e: any) {
            // eslint-disable-next-line no-console
            console.log('Error fetching user content:', e)
        } finally {
            setIsUpdating(false)
            setShowSpinner(false)
        }
    }

    useEffect(() => {
        const updating = searchParams.get('updating') === 'true'
        // Fetch user content from API unless username is demo
        fetchUserContent(updating)

        if (updating) {
            const interval = setInterval(() => {
                setDots((prev) => (prev.length < 3 ? prev + '.' : '.'))
            }, 500)
            return () => clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        // Set the most recent conversation as default
        if (conversations.length > 0) {
            setSelectedConversation(conversations[0])
        }
    }, [conversations])

    useEffect(() => {
        if (selectedConversation) {
            refreshVoicePrompt(selectedConversation)
        }
    }, [selectedConversation])

    const handleVoicePromptClicked = () => {
        // This is a placeholder function. In a real application, this would initiate voice recording.
        // eslint-disable-next-line no-console
        console.log('Enhancing your post...')
        //Redirect to the question link page for the voice prompt
        window.location.href = `/${voicePrompt?.author?.username}/${voicePrompt?.slug}`
        //toast.success('Voice recording started. Your response will be used to enhance your posts.')
    }

    const handleEdit = (post: UserContentPost) => {
        setSelectedPost(post)
        setIsEditDialogOpen(true)
    }

    const onPostUpdated = async (updatedPostId: string, updatedPostContent: string) => {
        setSelectedPost((prev) => (prev ? { ...prev, content: updatedPostContent } : null))
    }

    const handleShare = (sharedPost: UserContentPost) => {
        const isPostShared = !isShared
        setIsShared(isPostShared)
        const updatedConversations = conversations.map((conversation) => ({
            ...conversation,
            posts: conversation.posts.map((post) =>
                post.id === sharedPost.id ? { ...post, is_shared: isPostShared } : post,
            ),
        }))
        setConversations(updatedConversations)
        setSelectedConversation((prevConversation) => {
            if (!prevConversation) return null
            const updatedConversation = updatedConversations.find(
                (c) => c.id === prevConversation.id,
            )
            return updatedConversation || null
        })
        // eslint-disable-next-line no-console
        console.log('Post has been marked as shared for post id: ', sharedPost.id)
    }

    const handleCopy = async (post: UserContentPost) => {
        //Call api to track copy action of the post content
        if (username && username !== 'demo1') {
            const payload = {
                username,
                post_id: post.id,
                post_action: 'copied',
                updated_post_text: post.content,
            }
            try {
                const response = await UserContentApi.copyUserContentPost(payload)
                if (response.status === 200) {
                    // eslint-disable-next-line no-console
                    console.log('Post tracked for copy action for post id:', post.id)
                }
            } catch (e: any) {
                // eslint-disable-next-line no-console
                console.log('Error updating post to original:', e)
            }
        }
        navigator.clipboard.writeText(post.content)
        //toast.success('Copied to clipboard. You can now paste your post content on LinkedIn.')
        // toast({
        //     title: 'Copied to clipboard',
        //     description: 'You can now paste your post content on LinkedIn.',
        // })
    }

    const handleBackToOriginal = async (revertedPost: UserContentPost) => {
        //get latest version index from the original content
        let previousPostIndex: number = 0
        if (revertedPost.original_content && revertedPost.original_content.length > 1) {
            previousPostIndex = revertedPost.original_content.length - 2
        } else if (revertedPost.original_content && revertedPost.original_content.length === 1) {
            previousPostIndex = 0
        }

        if (username && username !== 'demo1') {
            const payload: UserContentPayload = {
                username,
                post_id: revertedPost.id,
                post_action: 'reset',
                updated_post_text: revertedPost.original_content[previousPostIndex].post,
            }
            try {
                const response = await UserContentApi.updateUserContentPost(payload)
                if (response.status === 200) {
                    // eslint-disable-next-line no-console
                    console.log('Post updated to original for post id:', revertedPost.id)
                    // eslint-disable-next-line no-console
                    console.log('Post updated to original response:', response.data)
                    //update the edited flag to false
                    if (response.data) {
                        revertedPost = response.data
                    } else {
                        revertedPost.content = revertedPost.original_content[previousPostIndex].post
                        if (previousPostIndex < 2) {
                            revertedPost.edited = false
                        } else {
                            revertedPost.edited = true
                        }
                    }
                }
            } catch (e: any) {
                // eslint-disable-next-line no-console
                console.log('Error updating post to original:', e)
            }
        }
        const updatedConversations = conversations.map((conversation) => ({
            ...conversation,
            posts: conversation.posts.map((post) =>
                post.id === revertedPost.id
                    ? {
                          ...post,
                          edited: revertedPost.edited,
                          content: revertedPost.content,
                      }
                    : post,
            ),
        }))
        setConversations(updatedConversations)
        setSelectedConversation((prevConversation) => {
            if (!prevConversation) return null
            const updatedConversation = updatedConversations.find(
                (c) => c.id === prevConversation.id,
            )
            return updatedConversation || null
        })
    }

    // const handleProfileLinkSubmit = async (e: React.FormEvent) => {
    //     e.preventDefault()
    //     setIsAnalyzing(true)

    //     // Simulating API call to analyze LinkedIn profile
    //     await new Promise((resolve) => setTimeout(resolve, 3000))

    //     // Mock suggestions (in a real app, these would come from the API)
    //     const mockSuggestions: ProfileSuggestion[] = [
    //         {
    //             category: 'Description',
    //             suggestion: 'Add more details about your key achievements and skills.',
    //         },
    //         {
    //             category: 'Pinned Posts',
    //             suggestion: 'Pin your most impactful posts to showcase your expertise.',
    //         },
    //         {
    //             category: 'Skills',
    //             suggestion: 'Add more relevant skills to your profile to improve searchability.',
    //         },
    //     ]

    //     setProfileSuggestions(mockSuggestions)
    //     setIsAnalyzing(false)
    // }

    const scrollToPost = (postId: string) => {
        const postElement = document.getElementById(`post-${postId}`)
        const postButtonElement = document.getElementById(`post-button-${postId}`)
        if (postButtonElement) {
            // Remove existing highlights
            document
                .querySelectorAll('.post-selected')
                .forEach((el) => el.classList.remove('post-selected'))

            // Add highlight effect
            postButtonElement.classList.add('post-selected')
        }
        if (postElement) {
            postElement.scrollIntoView({ behavior: 'smooth', block: 'start' })

            // Remove existing highlights
            document
                .querySelectorAll('.post-highlight')
                .forEach((el) => el.classList.remove('post-highlight'))

            // Add highlight effect
            postElement.classList.add('post-highlight')

            // Optionally remove highlight after 2 seconds
            // setTimeout(() => {
            //     postElement.classList.remove('post-highlight')
            // }, 2000)
        }
    }

    return (
        <div className="flex flex-col md:flex-row h-screen bg-blue-50 font-sans">
            <button
                className="md:hidden fixed top-4 left-4 z-50 bg-blue-500 text-white p-2 rounded-full"
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
                {isSidebarOpen ? 'Close' : 'Menu'}
            </button>
            <aside
                className={`w-full md:w-64 bg-blue-100 p-4 overflow-auto flex-none ${
                    isSidebarOpen ? 'fixed inset-0 z-40' : 'hidden md:block'
                }`}
            >
                <h2 className="text-xl font-medium mb-4 text-blue-900">Conversations</h2>
                <ScrollArea className="h-[calc(100vh-8rem)]">
                    {conversations.map((conversation) => (
                        <div key={conversation.id} className="mb-4">
                            <button
                                className={`w-full text-left p-2 rounded-lg transition-colors ${
                                    selectedConversation?.id === conversation.id
                                        ? 'bg-blue-200'
                                        : 'hover:bg-blue-200'
                                }`}
                                onClick={() => {
                                    setSelectedConversation(conversation)
                                    setIsSidebarOpen(false)
                                }}
                            >
                                <h3 className="font-medium text-blue-800">{conversation.title}</h3>
                                <p className="text-sm text-blue-600 flex items-center">
                                    <CalendarDays size={14} className="mr-1" />
                                    {formatUserContentLinkDate(conversation.date)}
                                </p>
                                <p className="text-sm text-blue-600 mt-1">
                                    {conversation.posts.length} post
                                    {conversation.posts.length !== 1 ? 's' : ''}
                                </p>
                            </button>
                            {selectedConversation?.id === conversation.id && (
                                <div className="ml-4 mt-2">
                                    {conversation.posts.map((post) => (
                                        <button
                                            key={post.id}
                                            id={`post-button-${post.id}`}
                                            className="w-full text-left p-2 rounded-lg hover:bg-blue-200 flex items-center"
                                            onClick={() => {
                                                setSelectedPost(post)
                                                setIsSidebarOpen(false)
                                                scrollToPost(post.id)
                                            }}
                                        >
                                            <span className="text-sm truncate flex-grow text-blue-700">
                                                {post.title}
                                            </span>
                                            {post.is_shared && (
                                                <Check size={14} className="text-green-500" />
                                            )}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </ScrollArea>
            </aside>
            <main className="flex-grow p-4 overflow-auto">
                <div>{showSpinner && <Spinner />}</div>
                <h1 className="text-2xl font-medium text-blue-900">
                    Hey {selectedConversation?.posts[0]?.user?.first_name}, your latest content
                    ideas
                </h1>
                <p className="text-base text-blue-600 mb-4">
                    Check back for new ideas that strategically reach your target audience
                </p>
                {selectedConversation && voicePrompt && unansweredTakeawayLinkExists && (
                    <VoicePrompt
                        question={voicePrompt}
                        onVoicePromptClicked={handleVoicePromptClicked}
                    />
                )}
                {selectedConversation ? (
                    <div>
                        <h2 className="text-xl font-medium mb-2 text-blue-800">
                            {selectedConversation.title}
                        </h2>
                        <p className="text-blue-600 mb-4">
                            <CalendarDays className="inline mr-2" size={16} />
                            {formatUserContentLinkDate(selectedConversation.date)}
                        </p>
                        <div className="space-y-6">
                            {selectedConversation.posts.map((post) => (
                                <ContentLinkCard
                                    key={post.id}
                                    id={`post-${post.id}`}
                                    post={post}
                                    isShared={post.is_shared}
                                    hasBeenEdited={post.edited}
                                    handleEdit={handleEdit}
                                    handleShare={handleShare}
                                    handleCopy={handleCopy}
                                    handleBackToOriginal={handleBackToOriginal}
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    <p className="text-blue-700">
                        Select a conversation from the sidebar to view its posts.
                    </p>
                )}
                <p className="text-sm text-blue-500 mt-4 text-center">
                    Note: Like, comment, and share icons are for illustrative purposes only.
                </p>
            </main>

            <Modal show={isEditDialogOpen} size="3xl" id="modal" className="z-[100000]">
                <div className="p-6 text-center">
                    {/* <svg
                        className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                        fill="none"
                        stroke="#007AFF"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                    </svg> */}
                    <h3 className="mb-5 text-lg font-normal text-gray-500">Edit LinkedIn Post</h3>
                    <Textarea
                        className="mb-4"
                        value={selectedPost?.content}
                        onChange={(e) => onPostUpdated(selectedPost?.id || '', e.target.value)}
                        rows={12}
                    />
                    <button
                        onClick={() => setIsEditDialogOpen(false)}
                        type="button"
                        className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium items-center justify-center px-5 py-2.5 hover:text-gray-900 focus:z-10 mr-4 w-[100px]"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={async () => {
                            if (selectedPost) {
                                if (username && username !== 'demo1') {
                                    const payload = {
                                        username,
                                        post_id: selectedPost.id,
                                        post_action: 'edited',
                                        updated_post_text: selectedPost.content,
                                    }
                                    try {
                                        const response = await UserContentApi.updateUserContentPost(
                                            payload,
                                        )
                                        if (response.status === 200) {
                                            // eslint-disable-next-line no-console
                                            console.log(
                                                'Post updated for post id:',
                                                selectedPost.id,
                                            )
                                            // eslint-disable-next-line no-console
                                            console.log(
                                                'Post updated response:',
                                                response.data.original_content,
                                            )
                                            if (response.data) setSelectedPost(response.data)
                                            selectedPost.edited = response.data.edited
                                            // if (selectedPost) {
                                            //     selectedPost.edited = true
                                            // }
                                        }
                                    } catch (e: any) {
                                        // eslint-disable-next-line no-console
                                        console.log('Error updating post:', e)
                                    }
                                }
                                const updatedConversations = conversations.map((conversation) => ({
                                    ...conversation,
                                    posts: conversation.posts.map((post) =>
                                        post.id === selectedPost.id
                                            ? { ...post, content: selectedPost.content }
                                            : post,
                                    ),
                                }))
                                setConversations(updatedConversations)
                                setSelectedConversation((prevConversation) => {
                                    if (!prevConversation) return null
                                    const updatedConversation = updatedConversations.find(
                                        (c) => c.id === prevConversation.id,
                                    )
                                    return updatedConversation || null
                                })
                            }
                            setIsEditDialogOpen(false)
                        }}
                        type="button"
                        className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center justify-center px-5 py-2.5 text-center w-[100px]"
                    >
                        Save
                    </button>
                </div>
            </Modal>

            <Modal show={isUpdating} size="2xl" id="progress-modal" className="z-[100000]">
                <Modal.Body className="flex flex-col items-center gap-4 p-6 bg-white rounded-lg shadow-lg">
                    <motion.div
                        animate={{ rotate: 360 }}
                        transition={{ repeat: Infinity, duration: 1, ease: 'linear' }}
                    >
                        <LoaderCircle className="w-12 h-12 text-blue-500 animate-spin" />
                    </motion.div>
                    <motion.p
                        animate={{ opacity: [0.5, 1, 0.5] }}
                        transition={{ repeat: Infinity, duration: 1.5 }}
                        className="text-lg font-semibold text-gray-700"
                    >
                        Processing latest changes{dots}
                    </motion.p>
                </Modal.Body>
            </Modal>
        </div>
    )
}
