import React from 'react'
import { useTranslation } from 'react-i18next'

interface SubmitButtonProps {
    onClick: () => void
    disabled?: boolean
    isTakeawayLink?: boolean
}

const SubmitButton = ({
    onClick,
    disabled,
    isTakeawayLink = false,
}: SubmitButtonProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <button
            onClick={onClick}
            className={`${
                disabled ? 'bg-[#add4ff]' : 'bg-[#007AFF]'
            } rounded-[10px] flex justify-center items-center w-[90px] sm:w-[120px] px-[4px] sm:px-[6px] py-[4px] gap-3`}
            disabled={disabled}
        >
            <p
                className={`${
                    disabled ? 'text-[#AFAFAF]' : 'text-[#BFDEFF]'
                } text-sm sm:text-base leading-5 font-lato font-bold`}
            >
                {isTakeawayLink ? t('common.talk-to-toni') : t('common.submit')}
            </p>
        </button>
    )
}

export default SubmitButton
